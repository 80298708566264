import React from "react";
import styles from "./ShowAd.module.css";

export interface Ad {
  htmlContent: string;
}

export interface ShowAdProps extends React.HTMLProps<HTMLDivElement> {
  ad: Ad;
}

const ShowAd: React.FC<ShowAdProps> = ({ ad, ...props }) => {
  return (
    <div
      className={styles.wrap}
      dangerouslySetInnerHTML={ad ? { __html: ad.htmlContent } : undefined}
      {...props}
    ></div>
  );
};

export default ShowAd;
