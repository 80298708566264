import React from "react";
import styles from "./Winner.module.css";
import { QuizContext } from "../QuizContext";

const Winner: React.FC = () => {
  const quiz = QuizContext.useContainer();

  return (
    <div className={styles.modal}>
      <div className={styles.message}>
        <p>Congrats {quiz.username}</p>
        <p>You were number {quiz.rank}!</p>
        {/* <p>Contact staff for price</p> */}
      </div>
    </div>
  );
};

export default Winner;
