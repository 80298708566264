import React, { useState } from "react";
import Highscores from "../components/Highscores";
import ShowAd from "../components/ShowAd";
import useInterval from "../hooks/useInterval";
import { QuizContext } from "../QuizContext";
import styles from "./RoundEnd.module.css";

export interface RoundEndProps {
  cycleTime?: number;
  adTime?: number;
}

const RoundEnd: React.FC<RoundEndProps> = ({ cycleTime = 12000, adTime = 9000 }) => {
  const quiz = QuizContext.useContainer();
  const [showAd, setShowAd] = useState(false);
  const [time, setTime] = useState(0);

  useInterval(() => {
    let newTime = time + 1000;
    if (((time / cycleTime) | 0) !== ((newTime / cycleTime) | 0)) {
      // TODO: switch ad
    }
    setTime(newTime);
    setShowAd(time % cycleTime > cycleTime - adTime);
  }, 1000);

  const secondsToNextRound = (Math.max(0, quiz.duration - quiz.elapsed - time) / 1000) | 0;

  return (
    <>
      <div className={styles.nextRound}>Next round in {secondsToNextRound}s</div>
      {showAd ? (
        <ShowAd ad={quiz.ad} />
      ) : (
        <Highscores entries={quiz.result.highscores} rank={quiz.rank} />
      )}
    </>
  );
};

export default RoundEnd;
