import React from "react";
import Answer from "./components/Answer";
import TimeSlider from "./components/TimeSlider";
import styles from "./Question.module.css";
import { QuizContext } from "./QuizContext";

export interface QuestionProps {
  submitAnswer: (answer: string) => void;
  isSpectator: boolean;
}

const Question: React.FC<QuestionProps> = ({ submitAnswer, isSpectator }) => {
  const quiz = QuizContext.useContainer();
  const showTimer = ["answers"].indexOf(quiz.state) >= 0;
  const showAnswers = ["answers", "questionResult"].indexOf(quiz.state) >= 0;
  let correctAnswer = "";
  let votePercents: number[] | undefined;
  if (quiz.progress.id === quiz.question.id) {
    votePercents = quiz.progress.votePercents;
  }
  if (quiz.result.id === quiz.question.id) {
    votePercents = quiz.result.votePercents;
    correctAnswer = quiz.result.correctAnswer;
  }
  const active = !isSpectator && quiz.result.id !== quiz.question.id;

  return (
    <div className={styles.questionWrap}>
      <div className={styles.questionInfo}>
        <TimeSlider
          duration={quiz.duration}
          startTime={quiz.startTime}
          elapsed={quiz.elapsed}
          enabled={showTimer}
        >
          {quiz.question.index}/{quiz.question.perRound} {quiz.question.category}
        </TimeSlider>
      </div>
      <div className={styles.question}>{quiz.question.question}</div>
      {showAnswers && (
        <div className={styles.answers}>
          {quiz.answers.map((a: string, idx: number) => {
            return (
              <Answer
                key={quiz.question.id + "-" + idx}
                selected={idx === quiz.answerIdx}
                wrong={
                  idx === quiz.answerIdx &&
                  quiz.result.id === quiz.question.id &&
                  a !== correctAnswer
                }
                correct={a === correctAnswer}
                progress={votePercents ? votePercents[idx] : 0}
                id={quiz.question.id + "-" + idx}
                onClick={(): void => {
                  if (!active) {
                    return;
                  }
                  quiz.setAnswerIdx(idx);
                  submitAnswer(a);
                }}
              >
                <span className={styles.votePercents}>
                  {votePercents ? `${votePercents[idx].toFixed(0)}%` : "0%"}
                </span>
                {a}
              </Answer>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Question;