import React, { useRef, useEffect, useState } from "react";
import styles from "./CategorySpinner.module.css";

export interface SliderProps {
  entries: string[];
  selectedIdx: number;
  duration: number;
  startTime: number;
}

const CategorySpjinner: React.FC<SliderProps> = ({ entries, selectedIdx, duration, startTime }) => {
  const [running, setRunning] = useState(true);
  const sliderEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const offset = (1 / entries.length) * (selectedIdx + ((entries.length / 2) % entries.length));
    const update = (now: number) => {
      const elapsed = (now - startTime) / 1000;
      if (elapsed < duration) {
        requestID = requestAnimationFrame(update);
      } else {
        setRunning(false);
        requestID = 0;
      }
      let t = Math.min(1, elapsed / duration);
      const tEase = --t * t * t + 1;
      const tx = ((tEase * 4 + offset) % 1) * 25 + 37.5;
      if (sliderEl && sliderEl.current) {
        sliderEl.current.setAttribute("style", `transform:translateX(-${tx}%)`);
      }
    };
    setRunning(true);
    let requestID = requestAnimationFrame(update);
    return () => {
      if (requestID) {
        cancelAnimationFrame(requestID);
      }
    };
  }, [duration, startTime, entries, selectedIdx]);

  const entryOutput = [];
  for (let i = 0; i < 4; ++i) {
    entryOutput.push(
      ...entries.map((c, idx) => (
        <div key={idx + i * entries.length} className={styles.entry}>
          {c}
        </div>
      )),
    );
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.slider} ref={sliderEl}>
        {entryOutput}
      </div>
      <div className={styles.selected + (running ? "" : " " + styles.stopped)}>
        {!running && (
          <div className={styles.entry + " " + styles.selectedEntry}>{entries[selectedIdx]}</div>
        )}
      </div>
    </div>
  );
};

export default CategorySpjinner;
