import ReactGA from "react-ga";

const GA_TRACKING_ID = process.env["REACT_APP_GA_TRACKING_ID"] || "UA-147102955-1";

export const initAnalytics = (): void => {
  ReactGA.initialize(GA_TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export const analyticsEvent = (category: string, action: string, label?: string, value?: number) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};
