import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";

const protocolPrefix = window.location.protocol === "https:" ? "wss:" : "ws:";
const WS_URL = `${protocolPrefix}//${window.location.host}/ws`;

function useQuiz() {
  const [state, setState] = useState("");
  const [username, setUsername] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [category, setCategory] = useState("");
  const [ad, setAd] = useState<any>(undefined);
  const [question, setQuestion] = useState<any>({});
  const [answers, setAnswers] = useState<string[]>([]);
  const [ws, setWs] = useState<WebSocket>();
  const [progress, setProgress] = useState<any>({});
  const [result, setResult] = useState<any>({});
  const [answerIdx, setAnswerIdx] = useState(-1);
  const [score, setScore] = useState(0);
  const [rank, setRank] = useState(0);
  const [duration, setDuration] = useState(0);
  const [elapsed, setElapsed] = useState(0);
  const [startTime, setStartTime] = useState(0);

  useEffect(() => {
    let ws: WebSocket;
    let reConnects = 0;
    let unmounting = false;
    let serverVersion = 0;

    let lastState = "";
    let timeoutID = 0;
    const updateState = (nextState: string): void => {
      if (lastState === nextState) {
        return;
      }
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
      setState(nextState);
      lastState = nextState;
    };

    const reConnect = () => {
      ++reConnects;
      if (reConnects > 4) {
        window.location.reload(true);
      }
      const delay = (Math.pow(2, Math.min(5, reConnects)) - 1) * 1000;
      setTimeout((): void => {
        console.log("re-connecting after", delay);
        connect();
      }, delay);
    };

    const connect = () => {
      ws = new WebSocket(WS_URL);
      setWs(ws);
      ws.onopen = () => {
        reConnects = 0;
        console.log("ws connected");
        updateState("init");
      };
      ws.onclose = () => {
        console.log("ws closed");
        updateState("closed");
        if (!unmounting) {
          reConnect();
        }
      };
      ws.onerror = () => {
        console.log("ws error");
      };
      ws.onmessage = async (ev): Promise<void> => {
        try {
          const msg = JSON.parse(ev.data as string);
          if (msg.op === "initOk") {
            // check version
            if (serverVersion && msg.version !== serverVersion) {
              window.location.reload(true);
            } else {
              serverVersion = msg.version;
            }

            // console.log("ws username:", msg.username);
            localStorage.setItem("userToken", msg.token);
            setUsername(msg.username);
            setCategories(msg.categories);
            updateState("initOk");
          }
          if(lastState === "init") {
            // block other msgs in init state
            return;
          }
          if(msg.duration) {
            setDuration(msg.duration);
            setElapsed(msg.elapsed || 0);
          }
          switch (msg.op) {
            case "category":
              // console.log("got category:", msg);
              setCategory(msg.category);
              setAd(msg.ad);
              updateState("category");
              break;
            case "question":
              // console.log("got question:", msg.question);
              setQuestion(msg.question);
              setAnswerIdx(-1);
              if (msg.question.index === 1) {
                // reset score
                setScore(0);
              }
              updateState("question");
              break;
            case "answers":
              setStartTime(Date.now());
              setAnswers(msg.answers);
              updateState("answers");
              break;
            case "progress":
              // console.log("got progress:", msg.progress);
              setProgress(msg.progress);
              break;
            case "questionEnd":
              // console.log("got questionEnd:", msg);
              setResult(msg.result);
              setScore(msg.score);
              setRank(msg.rank);
              updateState("questionResult");
              break;
            case "highscores":
              // console.log("got highscores:", msg);
              updateState("highscores");
              break;
            case "roundEnd":
              // console.log("got roundEnd:", msg);
              updateState("roundEnd");
              break;
            default:
              // console.log("got unknown:", msg.op);
              break;
          }
        } catch (error) {
          console.log("ws client got bad json");
        } finally {
        }
      };
    };

    connect();

    return () => {
      unmounting = true;
      console.log("ws closing");
      ws.close();
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    };
  }, []);

  return {
    state,
    ws,
    username,
    duration,
    elapsed,
    startTime,
    categories,
    category,
    ad,
    question,
    answers,
    progress,
    result,
    setResult,
    answerIdx,
    setAnswerIdx,
    score,
    rank,
  };
}

export const QuizContext = createContainer(useQuiz);
