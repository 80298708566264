import React from "react";
import styles from "./Highscores.module.css";

export interface HighscoreProps {
  entries: { username: string; score: number }[];
  rank: number;
}

const Highscores: React.FC<HighscoreProps> = ({ entries, rank }) => {
  return (
    <div className={styles.wrap}>
      <table className={styles.table}>
        <tbody className={styles.tbody}>
          {entries.map((e, idx) => (
            <tr key={idx} className={styles.tr + (rank === idx + 1 ? " " + styles.me : "")}>
              <td className={styles.rank}>{idx + 1}</td>
              <td className={styles.username}>{e.username}</td>
              <td className={styles.score}>{e.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Highscores;
