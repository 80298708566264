import React from "react";
import styles from "./Answer.module.css";

export interface AnswerProps extends React.HTMLProps<HTMLDivElement> {
  selected: boolean;
  correct: boolean;
  wrong: boolean;
  progress: number;
}

const Answer: React.FC<AnswerProps> = ({
  children,
  selected,
  wrong,
  correct,
  progress,
  ...props
}) => {
  let className = styles.answer;
  if (selected) {
    className += " " + styles.selected;
    if (wrong) {
      className += " " + styles.wrong;
    }
  }
  if (correct) {
    className += " " + styles.correct;
  }

  return (
    <div className={className} {...props}>
      <div className={styles.filler} style={{ width: `${progress}%` }} />
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Answer;
