import React, { useEffect, useState } from "react";
import {
  Formik,
  FormikActions,
  FormikProps,
  Form,
  Field,
  FieldProps,
  FormikErrors,
  ErrorMessage,
} from "formik";
import { QuizContext } from "../QuizContext";
import styles from "./SignIn.module.css";

interface SignInFormValues {
  username: string;
}

export interface SignInProps {
  isSpectator?: boolean;
}

const SignIn: React.FC<SignInProps> = ({ isSpectator = false }) => {
  const quiz = QuizContext.useContainer();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!quiz.ws) {
      return;
    }
    const token = localStorage.getItem("userToken") || undefined;
    if (token || isSpectator) {
      quiz.ws.send(JSON.stringify({ op: "init", token }));
      setSubmitting(true);
    }
  }, [quiz.ws, isSpectator]);

  const chooseRandomName = (): void => {
    if (!quiz.ws) {
      alert("Connection to server failed");
      return;
    }
    quiz.ws.send(JSON.stringify({ op: "init" }));
    setSubmitting(true);
  };

  const submitForm = (values: SignInFormValues, actions: FormikActions<SignInFormValues>) => {
    if (!quiz.ws) {
      alert("Connection to server failed");
      actions.setSubmitting(false);
      return;
    }
    const username = values.username.trim();
    quiz.ws.send(JSON.stringify({ op: "init", username }));
    setSubmitting(true);
  };

  const validate = (values: SignInFormValues) => {
    let errors: FormikErrors<SignInFormValues> = {};

    if (!values.username) {
      errors.username = "Required";
    } else if (!/^[A-Z0-9._ +-]{4,16}$/i.test(values.username)) {
      errors.username = "Must be 4 to 16 characters";
    } else if (/\s{2,}/.test(values.username)) {
      errors.username = "Only single spaces";
    }

    return errors;
  };

  return (
    <div>
      {submitting ? (
        <div>Signing in ...</div>
      ) : (
        <>
          <div className={styles.header}>Sign in</div>
          <div className={styles.content}>
            <Formik
              initialValues={{ username: "" }}
              validate={validate}
              onSubmit={submitForm}
              render={(formikBag: FormikProps<SignInFormValues>) => (
                <Form>
                  <Field
                    name="username"
                    render={({ field, form }: FieldProps<SignInFormValues>) => (
                      <div>
                        <input
                          className={styles.input}
                          type="text"
                          {...field}
                          placeholder="Desired Username"
                          maxLength={16}
                        />
                        <ErrorMessage name="username" component="div" className={styles.error} />
                      </div>
                    )}
                  />
                  <button className={styles.button} type="submit">
                    Sign In
                  </button>
                </Form>
              )}
            />
            <p>
              <em>- or -</em>
            </p>
            <button className={styles.button} onClick={chooseRandomName}>
              Use random name
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SignIn;
