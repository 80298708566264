import React, { useEffect, useState } from "react";
import styles from "./TimeSlider.module.css";

interface TimeSliderProps {
  startTime: number;
  duration: number;
  elapsed: number;
  enabled?: boolean;
}

const TimeSlider: React.FC<TimeSliderProps> = ({
  children,
  startTime,
  duration,
  elapsed,
  enabled = true,
}) => {
  const [remain, setRemain] = useState(duration);
  const [transDur, setTransDur] = useState(1);
  useEffect(() => {
    setTransDur(0);
    setRemain(Math.max(0, duration - elapsed));

    const update = (rem: number): void => {
      if (rem < 1000) {
        setTransDur(rem / 1000);
      } else {
        setTransDur(1);
      }
      setRemain(Math.max(0, rem - 1000));
    };

    let timerID = setTimeout(() => {
      update(duration - elapsed);
      timerID = setInterval(() => {
        const rem = Math.max(0, duration - elapsed - (Date.now() - startTime));
        update(rem);
      }, 1000);
    }, 10);
    return () => {
      clearInterval(timerID);
    };
  }, [startTime, duration, elapsed]);

  const filler1Style = {
    width: `${(remain * 100) / duration}%`,
    transitionDuration: `${transDur}s`,
  };
  return (
    <div className={styles.wrap}>
      {enabled && <div className={styles.filler1} style={filler1Style} />}
      <div className={styles.content}>{children}</div>
      {/* <div>{(remain / 1000).toFixed(0)}s</div> */}
    </div>
  );
};

export default TimeSlider;
