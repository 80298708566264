import React from "react";
import { QRCode } from "react-qr-svg";
import CategroySpinner from "./components/CategorySpinner";
import Question from "./Question";
import Highscores from "./components/Highscores";
import ShowAd from "./components/ShowAd";
import styles from "./Quiz.module.css";
import { QuizContext } from "./QuizContext";
import { analyticsEvent } from "./analytics";
import RoundEnd from "./containers/RoundEnd";
import SignIn from "./containers/SignIn";
import Winner from "./containers/Winner";

const UserInfo: React.FC = () => {
  const quiz = QuizContext.useContainer();
  const signOut = (): void => {
    if (window.confirm("Sign out?")) {
      localStorage.removeItem("userToken");
      window.location.reload();
    }
  };
  return (
    <div className={styles.userInfo}>
      {quiz.username && (
        <>
          <div className={styles.username} onClick={signOut}>{quiz.username}</div>
          <div>Score: {quiz.score}</div>
        </>
      )}
    </div>
  );
};

const Quiz: React.FC = () => {
  const quiz = QuizContext.useContainer();

  const submitAnswer = (answer: string): void => {
    if (!quiz.ws) {
      return;
    }
    analyticsEvent("answer", "select");
    quiz.ws.send(
      JSON.stringify({
        op: "answer",
        answer: {
          questionId: quiz.question.id,
          answer,
        },
      }),
    );
  };

  const showSignIn = ["init"].indexOf(quiz.state) >= 0;

  //TODO: use router?
  const isSpectator = window.location.pathname.indexOf("spectate") > -1 // window.location.pathname === "/spectate";
  //if (!isSpectator) {
  //    window.alert(window.location.pathname);
  //}
  const showUserInfo = !isSpectator && !showSignIn;

  const showQuestion = ["question", "answers", "questionResult"].indexOf(quiz.state) >= 0;
  const showAd = ["category"].indexOf(quiz.state) >= 0;

  const showHighscores = ["highscores"].indexOf(quiz.state) >= 0;
  const showRoundEnd = ["roundEnd"].indexOf(quiz.state) >= 0;

  const showWinner = showRoundEnd && quiz.rank === 1;

  // const showQuestion = false;
  // const showAnswers = false;
  // const showTimer = false;
  // const showAd = false;
  // const showHighscores = true;
  // const rank = 3;
  // const highscores = [
  //   { score: 1934, username: "FoolishAviatior" },
  //   { score: 1526, username: "AngelicMoustetrap" },
  //   { score: 1234, username: "WhiteFlowerpole" },
  //   { score: 1087, username: "ShinyPretence" },
  //   { score: 933, username: "MoldenElephant" },
  //   { score: 825, username: "RespectfulAdoption" },
  //   { score: 624, username: "HumanHockey" },
  //   { score: 321, username: "SplatterVogue" },
  //   { score: 267, username: "AccountantOwner" },
  //   { score: 236, username: "FancireCemetary" },
  // ];

  const qrlink = window.location.origin;

  return (
    <div className={styles.container}>
      {showUserInfo && <UserInfo />}
      {showSignIn && <SignIn isSpectator={isSpectator} />}
      {quiz.state === "category" && (
        <div>
          <CategroySpinner
            startTime={performance.now()}
            duration={Math.max(0.5, quiz.duration - quiz.elapsed - 5000) / 1000}
            entries={quiz.categories}
            selectedIdx={quiz.categories.indexOf(quiz.category)}
          />
        </div>
      )}
      {showAd && <ShowAd ad={quiz.ad} />}
      {showQuestion && <Question submitAnswer={submitAnswer} isSpectator={isSpectator} />}
      {showHighscores && <Highscores entries={quiz.result.highscores} rank={quiz.rank} />}
      {showRoundEnd && <RoundEnd />}
      {showWinner && <Winner />}

      {isSpectator && !showAd && ( // TODO: Better way to hide/move QR code when showing add
        <div className={styles.qr}>
          <QRCode value={qrlink} level="M" />
        </div>
      )}
    </div>
  );
};

export default Quiz;
