import React from "react";
import "./App.css";
import { QuizContext } from "./QuizContext";
import Quiz from "./Quiz";
import { initAnalytics } from "./analytics";

const App: React.FC = () => {
  return (
    <div className="App">
      <QuizContext.Provider>
        <Quiz />
      </QuizContext.Provider>
    </div>
  );
};

initAnalytics();

export default App;
